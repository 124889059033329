import { DOCUMENT, CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Directive, Inject, Input, NgModule } from '@angular/core';
class FlyLinkDirective {
  constructor(elementRef, renderer, document) {
    this.elementRef = elementRef;
    this.renderer = renderer;
    this.document = document;
    this.internalSVG = '<svg class="fly-link-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M14 8L10 5V7H2V9H10V11L14 8Z" fill="currentcolor"/></svg>';
    this.externalSVG = '<svg class="fly-link-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="2" y="2" width="2" height="12" fill="currentcolor"/><rect x="14" y="12" width="2" height="12" transform="rotate(90 14 12)" fill="currentcolor"/><path d="M6 2L8 4L2 4L2 2L6 2Z" fill="currentcolor"/><path d="M14 2L14 7L9 2L14 2Z" fill="currentcolor"/><path d="M14 14L12 14L12 8L14 10L14 14Z" fill="currentcolor"/><rect x="6.63948" y="10.7747" width="2" height="9.1675" transform="rotate(-135 6.63948 10.7747)" fill="currentcolor"/></svg>';
  }
  ngOnInit() {
    const icon = this.document.createElement('div');
    if (this.flyLinkType === 'internal') {
      icon.innerHTML = this.internalSVG;
    } else if (this.flyLinkType === 'external' || this.elementRef.nativeElement.host !== this.document.location.host) {
      icon.innerHTML = this.externalSVG;
    } else {
      icon.innerHTML = this.internalSVG;
    }
    this.renderer.appendChild(this.elementRef.nativeElement, icon.firstChild);
  }
  ngAfterViewInit() {
    if (this.elementRef.nativeElement.tagName.toLocaleUpperCase() !== 'A' && this.elementRef.nativeElement.tabIndex === -1) {
      this.elementRef.nativeElement.tabIndex = 0;
    }
  }
  static {
    this.ɵfac = function FlyLinkDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || FlyLinkDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(DOCUMENT));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: FlyLinkDirective,
      selectors: [["", "flyLink", ""]],
      inputs: {
        flyLinkType: "flyLinkType"
      },
      standalone: false
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FlyLinkDirective, [{
    type: Directive,
    args: [{
      selector: '[flyLink]',
      standalone: false
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i0.Renderer2
  }, {
    type: Document,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }], {
    flyLinkType: [{
      type: Input
    }]
  });
})();
class FlyLinkModule {
  static {
    this.ɵfac = function FlyLinkModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || FlyLinkModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: FlyLinkModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FlyLinkModule, [{
    type: NgModule,
    args: [{
      declarations: [FlyLinkDirective],
      imports: [CommonModule],
      exports: [FlyLinkDirective]
    }]
  }], null, null);
})();

/*
 * Public API Surface of avcloud-ui-common/link
 */

/**
 * Generated bundle index. Do not edit.
 */

export { FlyLinkDirective, FlyLinkModule };
